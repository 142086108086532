import SecureLS from 'secure-ls'
import Vue from 'vue'

/**
 * Clase encargada de gestionar el acceso a los datos de usuario en session
 * Los datos del usuario estan encriptados de tal forma que sean seguros al momento
 * de navegar desde el navegador.
 */
export default class SesionData {
  constructor() {
    this.ls = new SecureLS({
      encodingType: 'aes',
      isCompression: false,
    })
  }

    networkId = null

    context = null

    // datos del kyc
    data = null

    // encriptar y desencriptar datos
    ls = null

    // crowdfunding contracts
    mostGeneticsContract = null

    /**
     * Guardar los datos de sesion
     * @param {datos KYC a almacenar} data
     */
    set(data) {
      this.ls.set(((data.token !== '') ? data.token : 'usrTmp'), data)
      this.ls.set('token', (data.token) ? data.token : '')
    }

    setNetwork(netId) {
      this.networkId = netId
      localStorage.setItem('currentNetId', netId)
    }

    getCurrentNetworkId() {
      this.networkId = localStorage.getItem('currentNetId')
      return this.networkId
    }

    getNetwork() {
      return this.networkId
    }

    getCurrentNetworkData() {
      const blockchains = [
        {
          title: 'Binance (BSC) Mainnet',
          icon: 'BoxIcon',
          id: 0x38,
          name: 'Binance Coin',
          provider: 'https://bsc-dataseed.binance.org/',
          scanner: 'https://bscscan.com',
          symbol: 'BNB',
        },
        // {
        //   title: 'Ethereum Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0x1,
        //   name: 'Ether',
        //   provider: 'https://mainnet.infura.io/v3/',
        //   scanner: 'https://etherscan.io',
        //   symbol: 'ETH',
        // },
        // {
        //   title: 'Matic Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0x89,
        //   name: 'Polygon Coin',
        //   provider: 'https://rpc-mainnet.maticvigil.com/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'MATIC',
        // },
        // {
        //   title: 'Binance Smart Chain (TESTNET)',
        //   icon: 'BoxIcon',
        //   id: 0x61,
        //   name: 'Binance Coin',
        //   provider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'BNB',
        // },
        // {
        //   title: 'CELO Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0xA4EC,
        //   name: 'CELO',
        //   provider: 'https://celo-mainnet.infura.io',
        //   scanner: 'https://celoscan.io',
        //   symbol: 'CELO',
        // },
        // {
        //   title: 'GANACHE Mainnet',
        //   icon: 'BoxIcon',
        //   id: 5777,
        //   name: 'GANACHE',
        //   provider: 'HTTP://127.0.0.1:7545',
        //   scanner: '',
        //   symbol: 'BNB',
        // },
      ]
      if (!this.networkId) {
        this.getCurrentNetworkId()
      }
      if (!this.networkId) {
        if (window.ethereum.networkVersion) {
          this.setNetwork(parseFloat(window.ethereum.networkVersion))
        }
      }
      const blockchn = blockchains.filter(blockchain => blockchain.id === parseFloat(this.networkId))[0]
      return !blockchn ? { title: 'Non blockchain selected' } : blockchn
    }

    // Asignar el contexto de la app a la instancia de la clase
    setContext(context) {
      this.context = context
    }

    /**
     * Obtener los datos del usuario guardado en sesion
     * @returns Objeto JSON user data
     */
    get() {
      try {
        if (this.validateSession()) {
          // Obtener de forma segura la informacion del usuario en el navegador
          this.data = this.ls.get(this.ls.get('token'))
          if (this.data === '') this.data = null
        }
      } catch (err) {
        // this.context.$router.push('login').catch(() => {})
      }
      return this.data
    }

    // actualizar los datos de KYC almacenados y devolver los datos de sesion
    updateAndGet() {
      this.updateKycSession()
        .then(() => this.data)
    }

    /**
     * Asignar a la sesion los datos del usuario que vengan del api
     */
    getAndSet() {
      this.set(this.get())
    }

    /**
     * Borrar los datos de sesion
     */
    removeAll() {
      this.ls.removeAll()
      this.data = null
    }

    /**
     * Retornar los datos del usuario guardados en sesion
     * @returns user data object
     */
    validateSession() {
      let usrData = this.ls.get(this.ls.get('token'))
      if (usrData === '') {
        usrData = this.ls.get('usrTmp')
      }
      this.validToken()
      // Si no existe sesion
      if (!usrData.data) {
        return false
      }
      return true
    }

    /**
     * Actualizar los datos KYC del usuario del objeto almacenado en LocalStorage
     * @returns User session data kyc update new Object
     */
    updateKycSession() {
      return new Promise((resolve, reject) => {
        try {
          // si aun no se tiene los datos guardados en el navegador, hacemos una llamada para saber si existen
          if (this.data === null) this.get()
          if (this.data === null) {
            reject(new Error('fail'))
          }
          // obtenemos los datos en base de datos (KYC)
          this.context.$http.defaults.headers['hey!'] = 'jg3*'
          this.context.$http.defaults.headers['x-access-token'] = this.data.token
          this.context.$http.get(`/kyc/${this.data.data.invitation.email}/email?nid=${this.networkId}`)
            .then(response => {
              this.data.data.kyc = response.data.kyc
              resolve(this.data)
            })
            .catch(error => {
              reject(error)
            })
        } catch (e) {
          reject(e)
        }
      })
    }

    validateKyc(ToastificationContent, toast) {
      if (this.data.data.kyc) {
        if (!this.data.data.kyc.wallet || this.data.data.kyc.accountActive === '0') {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: 'Please fill out the KYC form to unlock Bancannabis features.',
              variant: 'warning',
            },
          },
          {
            position: 'bottom-center',
            timeout: 5000,
          })
          return false
        }
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'Please fill the KYC form to continue...',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-center',
          timeout: 5000,
        })
        return false
      }
      return true
    }

    /**
     * Verificar si el token de sesion es valido o no
     * @returns true if token has been expired
     */
    async validToken() {
      try {
        if (!this.context) return false
        if (this.data === null) this.get()
        if (this.data === null) return false
        this.context.$http.defaults.headers['x-access-token'] = this.data.token
        await this.context.$http.get(`/auth/token?nid=${this.networkId}`)
          .then(data => {
            if (!data.data.valid) {
              this.context.$router.push('/login').catch(() => {})
              return false
            }
            return true
          }).catch(() => {
            this.context.$router.push('/login').catch(() => {})
            return false
          })
      } catch (err) {
        return false
      }
      return true
    }

    getUsrEmail() {
      try {
        return this.data.data.invitation.email
      } catch (er) {
        return ''
      }
    }

    getUsrWallet() {
      try {
        this.get()
        return this.data.data.invitation.wallet
      } catch (er) {
        return ''
      }
    }

    /**
     * Obtener y asignar el saldo de ethereum
     * @param {string} ethBalance Balance de eth de la direccion del usuario
     */
    setEthBalance(ethBalance) {
      if (this.validateSession()) {
        this.data.data.balanceEth = ethBalance
      }
    }

    getEthBalance() {
      try {
        return this.data.data.balanceEth
      } catch (err) {
        return 0
      }
    }

    setMostGeneticsContractObject(mostGeneticsContractObject) {
      if (this.validateSession()) {
        this.mostGeneticsContract = mostGeneticsContractObject
      }
    }

    // get
    getMostGeneticsContractObject() {
      try {
        return this.mostGeneticsContract
      } catch (err) {
        return 0
      }
    }
}
Vue.prototype.$sessionData = new SesionData()
