import Vue from 'vue'
import VueRouter from 'vue-router'
// import SesionData from '../libs/sessionData'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: '/',
      props: route => ({ vc: route.query.vc }),
      component: () => import('@/views/Home.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Liquidity Pool Market',
        breadcrumb: [
          {
            text: 'Add liquidity, swap, and manage BCA pairs in the Bancannabis DeFi ecosystem.',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/e-project/list',
    //   name: 'e-project-list',
    //   component: () => import('@/views/e-project-list/e-project-shop/EProjectShop.vue'),
    //   meta: {
    //     contentRenderer: 'sidebar-left-detached',
    //     contentClass: 'ecommerce-application',
    //     pageTitle: 'Shop',
    //     breadcrumb: [
    //       {
    //         text: 'EProject',
    //       },
    //       {
    //         text: 'Shop',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/my-genetics',
      name: 'my-genetic',
      component: () => import('@/views/genetic-view/list/List.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Inventory',
        breadcrumb: [
          {
            text: 'View, use, transfer, or redeem your NFTs for store purchases or delivery.',
            active: true,
          },
        ],
      },
    },
    {
      path: '/wallet',
      name: 'wallet',
      component: () => import('@/views/wallet/AccountSetting.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Wallet',
        breadcrumb: [
          {
            text: 'View balances, deposit, send BCA, trade, access P2P, and complete KYC verification.',
            active: true,
          },
        ],
      },
    },
    {
      path: '/market',
      name: 'market',
      component: () => import('@/views/defi/DefiHome.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Market',
        breadcrumb: [
          {
            text: 'Market',
          },
          {
            text: 'Market',
            active: true,
          },
        ],
      },
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: () => import('@/views/pages/Welcome.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/build-company',
      name: 'build-company',
      component: () => import('@/views/company/CompanyHome.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Build Company',
        breadcrumb: [
          {
            text: 'Build Company',
          },
          {
            text: 'Build Company',
            active: true,
          },
        ],
      },
    },
    {
      path: '/portal',
      name: 'portal',
      component: () => import('@/views/Portal.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/kyc',
      name: 'kyc',
      component: () => import('@/views/forms/kyc/KycWizard.vue'),
      meta: {
        pageTitle: 'Know Your Customer',
        breadcrumb: [
          {
            text: 'kyc',
            active: true,
          },
        ],
      },
    },
    {
      path: '/cannaverse',
      name: 'cannaverse',
      component: () => import('@/views/pages/Cannaverse.vue'),
      meta: {
        pageTitle: 'Know the Cannaverse',
        // layout: 'full',
        breadcrumb: [
          {
            text: 'Cannaverse',
            active: true,
          },
        ],
      },
    },
    {
      path: '/rkyc',
      name: 'rkyc',
      component: () => import('@/views/kyc-admin/KycGeneral.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views/pages/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/e-project/list',
      name: 'e-project-list',
      component: () => import('@/views/e-project-list/e-project-shop/EProjectShop.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Shop',
        breadcrumb: [
          {
            text: 'EProject',
          },
          {
            text: 'Shop',
            active: true,
          },
        ],
      },
    },
    {
      path: '/e-commerce/list',
      name: 'e-commerce-list',
      component: () => import('@/views/e-project-list/e-commerce-shop/ECommerceShop.vue'),
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Market Place',
        breadcrumb: [
          {
            text: 'A crypto marketplace where users acquire, use, or trade NFTs for products, materials, and services.',
            active: true,
          },
        ],
      },
    },
    {
      path: '/e-project/wishlist',
      name: 'e-project-wishlist',
      component: () => import('@/views/e-project-list/e-project-wishlist/EProjectWishlist.vue'),
      meta: {
        pageTitle: 'Wishlist',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'EProject',
          },
          {
            text: 'Wishlist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/e-project/checkout',
      name: 'e-project-checkout',
      component: () => import('@/views/e-project-list/e-project-checkout/EProjectCheckout.vue'),
      meta: {
        pageTitle: 'Checkout',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'EProject',
          },
          {
            text: 'Checkout',
            active: true,
          },
        ],
      },
    },
    {
      path: '/e-project/:slug',
      name: 'e-project-product-details',
      component: () => import('@/views/e-project-list/e-project-product-details/EProjectProductDetails.vue'),
      meta: {
        pageTitle: 'Product Details',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'EProject',
          },
          {
            text: 'Shop',
            active: true,
          },
          {
            text: 'Product Details',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/company',
    //   name: 'your-company',
    //   component: () => import('@/views/forms/company/Company.vue'),
    //   meta: {
    //     pageTitle: 'B2B Solutions',
    //     breadcrumb: [
    //       {
    //         text: 'Business verification and tools to create a store, launch an ERC20 token, and set up a liquidity pool with BCA.',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/fabric',
      name: 'fabric',
      component: () => import('@/views/forms/fabric/Fabric.vue'),
      meta: {
        pageTitle: 'B2B Solutions',
        breadcrumb: [
          {
            text: 'Business verification and tools to create a store, launch an ERC20 token, and set up a liquidity pool with BCA.',
            active: true,
          },
        ],
      },
    },
    {
      path: '/apps/e-commerce/:slug',
      name: 'apps-e-commerce-product-details',
      component: () => import('@/views/e-project-list/e-commerce-product-details/ECommerceProductDetails.vue'),
      meta: {
        pageTitle: 'Product Details',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'ECommerce',
          },
          {
            text: 'Shop',
            active: true,
          },
          {
            text: 'Product Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/apps/e-commerce/checkout',
      name: 'apps-e-commerce-checkout',
      component: () => import('@/views/e-project-list/e-commerce-checkout/ECommerceCheckout.vue'),
      meta: {
        pageTitle: 'Checkout',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Review and manage your selected NFTs before completing your purchase.',
            active: true,
          },
        ],
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

// router.beforeResolve((to, from, next) => {
//   const sesionData = new SesionData().get()
//   if (sesionData.isZero) {
//     document.getElementById('loading-logo').style.display = 'none'
//     document.getElementById('loading-zero').style.display = ''
//   } else {
//     document.getElementById('loading-zero').style.display = 'none'
//     document.getElementById('loading-logo').style.display = ''
//   }
//   next()
// })

export default router
