import axios from '@axios'
import SessionData from '../../libs/sessionData'
import { paginateArray, sortCompare } from './utils'

export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    },
  },
  actions: {
    fetchProducts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/products', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/ecommerce/products/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGreenHouse(req, id) {
      return new Promise((resolve, reject) => {
        const currentNetwork = (new SessionData()).getCurrentNetworkData()
        axios
          .get(`/greenhouse/${id.productId}?nid=${currentNetwork.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGreenHouses() {
      return new Promise((resolve, reject) => {
        const sessionService = new SessionData()
        const currentNetwork = sessionService.getCurrentNetworkData()
        axios
          .get(`/greenhouse?nid=${currentNetwork.id}&who=`)
          .then(response => {
            resolve(
              {
                data: response.data.filter(project => project.active),
              },
            )
          })
          .catch(error => reject(error))
      })
    },
    /**
     * get all genetics of projects allowed to mint
     * @returns Listado de geneticas
     */
    fetchGeneticsOfProjects(...args) {
      return new Promise((resolve, reject) => {
        const currentNetwork = (new SessionData()).getCurrentNetworkData()
        axios
          .get(`/greenhouse/${args[1].greenHouseId}/genetic?nid=${currentNetwork.id}`)
          .then(response => {
            const { data } = response
            const paramPosition = 1
            const {
              q = '', sortBy = 'featured', perPage = 9, page = 1,
            } = args[paramPosition]
            const queryLowered = q.toLowerCase()
            const filteredData = data.filter(product => product.name.toLowerCase().includes(queryLowered))

            let sortDesc = false
            const sortByKey = (() => {
              if (sortBy === 'price-desc') {
                sortDesc = true
                return 'price'
              }
              if (sortBy === 'price-asc') {
                return 'price'
              }
              sortDesc = true
              return 'id'
            })()

            const sortedData = filteredData.sort(sortCompare(sortByKey))
            if (sortDesc) sortedData.reverse()

            const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))

            resolve({
              data: {
                greenHouse: paginatedData,
                total: filteredData.length,
              },
            })
          })
          .catch(error => reject(error))
      })
    },

    fetchGeneticsForSale(...args) {
      return this.fetchGeneticsOfProjects(args)
    },
    fetchMyGenetics(...args) {
      /* eslint-disable no-async-promise-executor */
      return new Promise(async (resolve, reject) => {
        const currentNetwork = (new SessionData()).getCurrentNetworkData()
        const paramPosition = 1
        const {
          q = '', sortBy = 'featured', perPage = 9, page = 1,
        } = args[paramPosition]
        const queryLowered = q.toLowerCase()
        let filteredData = []
        const usedsNftData = []
        let data
        if (args[1].cacheData) {
          if (args[1].cacheData.length <= 0) {
            /* eslint-disable no-param-reassign */
            args[1].cacheData = null
            filteredData = await this.fetchMyGenetics(args)
          } else {
            filteredData = args[1].cacheData
          }
        } else {
          await axios
            .get(`/greenhouse/0/inventory?nid=${currentNetwork.id}&pkey=${args[1].address}&swi=${args[1].swInact}`)
            .then(response => {
              data = response.data
              if (data.length > 0) {
                // Listado de proyectos
                for (let k = 0; k < data.length; k += 1) {
                  // tipos de artefactos creados
                  for (let j = 0; j < data[k].length; j += 1) {
                    // obtener nfts minteados
                    for (let i = 0; i < data[k][j].length; i += 1) {
                      const nft = data[k][j][i]
                      if (nft) {
                        if (nft.name.toLowerCase().includes(queryLowered)) {
                          // if (nft.isUsed) {
                          //   usedsNftData.push(nft)
                          // } else {
                          //   filteredData.push(nft)
                          // }
                          filteredData.push(nft)
                        }
                      }
                    }
                  }
                }
              }
            })
            .catch(error => reject(error))
        }
        // filteredData = data.filter(nftPlantData => nftPlantData.name.toLowerCase().includes(queryLowered))
        let sortDesc = false
        const sortByKey = (() => {
          if (sortBy === 'price-desc') {
            sortDesc = true
            return 'price'
          }
          if (sortBy === 'price-asc') {
            return 'price'
          }
          sortDesc = true
          return 'id'
        })()
        const sortedData = filteredData.sort(sortCompare(sortByKey))
        if (sortDesc) sortedData.reverse()

        const paginatedData = JSON.parse(JSON.stringify(paginateArray(sortedData, perPage, page)))
        resolve({
          data: {
            usedNfts: usedsNftData,
            genetics: paginatedData,
            total: filteredData.length,
            filteredData,
          },
        })
      })
    },

    fetchWishlistGreenHouse() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartGreenHouse() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { greenHouseId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { greenHouseId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { greenHouseId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${greenHouseId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, { greenHouseId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/cart', { greenHouseId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, { greenHouseId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/cart/${greenHouseId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
