import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://e-groweed.com:3800/api/v1',
  // baseURL: 'http://localhost:3800/api/v1',
  timeout: 60000,
  headers: { 'Content-Type': 'application/json', 'x-access-token': '' },
})

/**
 * Bancannabis Org connection
 */
// const axiosBcaOrg = axios.create({
//   baseURL: 'https://bancannabis.org',
//   timeout: 60000,
//   // headers: { 'Content-Type': 'application/json', 'x-access-token': '' }, s
//   headers: { 'Content-Type': 'multipart/form-data' },
// })

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      return Promise.reject(error.response)
    }
    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns
// Vue.prototype.$httpBca = axiosBcaOrg

export default axiosIns
